import React, {Component} from "react";
import ReactDOM from "react-dom";

import OrderForm from "../components/OrderForm";

import Tooltip from "../util/Tooltip";

class Book extends Component {

    state = {
        orderFormVisible: false,
        successfulOrder: false
    };

    constructor(props) {
        super(props);
        this.orderFormRef = React.createRef();
    }

    showForm = () => {
        this.setState({
            orderFormVisible: true,
            successfulOrder: false
        }, () => {
            const domNode = ReactDOM.findDOMNode(this.orderFormRef.current);
            domNode.scrollIntoView();
        });
    };

    onSuccessfulOrder = () => {
        this.setState({
            orderFormVisible: false,
            successfulOrder: true
        })
    };

    render() {
        return (
            <div id="book" className="container bg-3 text-center">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        <img className="rounded img-fluid" src="/java_2ed/cover.png"
                             alt="Szoftverfejlesztés JavaSE platformon"/>
                        <p className="text-center">Kartonált, B5, 232 oldal</p>
                        <p className="text-center">ISBN 978-615-00-2933-7</p>
                        <p className="text-center"><strong>5900 Ft + postktg.</strong>&#160;<Tooltip hint="A postaköltség a rendelési formon számítódik a rendelés paramétereitől függően">
                            <span className="fa fa-question-circle"/>
                        </Tooltip></p>
                        <button type="button" className="btn btn-primary" onClick={this.showForm}>Megrendelem</button>
                    </div>
                    <div className="col-md-8 col-sm-12 mb-3">
                        <h4>Szoftverfejlesztés Java SE platformon (Második kiadás)</h4>

                        <p>Napjainkra a szoftverfejlesztés egyik vezető platformjává a Java nyelv vált:
                            széles körben használják ipari és kutatási feladatok megvalósítására. A könyv
                            feltételezi, hogy az Olvasó már rendelkezik általános programozói alapismeretekkel.
                            A Java nyelv ismerete nem szükséges, de a könyv haladó témákat is tartalmaz, ezek
                            a tapasztaltabb fejlesztők számára is érdekesek. A nyelv a könyv írásakor
                            aktuális 11-es verzióját tárgyalja, és a jelenlegi programozási trendeket
                            és technológiákat követi az ismertetés során. Az első nyolc fejezet a
                            legalapvetőbb, a Java-programozók számára nélkülözhetetlen ismereteket
                            nyújtja. Ezek stabil alapot jelentenek a platform használatához. Idetartozik
                            a változótípusok és az utasítások ismertetése, a fejlett objektumorientált
                            eszköztár és az osztálykönyvtár áttekintése, a generikus kollekciók,
                            illetve a lambda-kifejezések és a Stream API bemutatása is. A későbbi
                            fejezetek haladó témákat mutatnak be, végigviszik az Olvasót az összetett
                            alkalmazások fejlesztése során felmerülő problémákon. Ezeket a fejezeteket
                            az alapokat már jól ismerő programozók külön is feldolgozhatják. A fejezetek
                            során olyan témákat érintünk, mint a programállapot elmentése, a
                            fájl- és könyvtárműveletek, a szálkezelés, a dátumok és az idő kezelése,
                            a futásidőben generikus programrészek készítése reflectionnel, valamint
                            a moduláris alkalmazások fejlesztése.</p>

                        <p>A szerző 2008 óta foglalkozik a Java nyelvvel, és 2013 óta oktat a Budapesti
                            Műszaki és Gazdaságtudományi Egyetemen, valamint rendelkezik az Oracle
                            által kiadott összes Java SE és Java EE minősítéssel.</p>
                    </div>
                    <div className="row mx-auto">
                        <div className="col-md-6 col-sm-12">
                            <h4>Letölthető anyagok</h4>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a
                                    href="/java_2ed/javase_2ed_toc.pdf">Tartalomjegyzék</a></li>
                                <li className="list-group-item"><a href="/java_2ed/javase_2ed_intro.pdf">Bevezetés</a>
                                </li>
                                <li className="list-group-item d-none"><a href="">Függelék: JDK telepítés</a></li>
                                <li className="list-group-item d-none"><a href="">Függelék: Eclipse használata</a></li>
                                <li className="list-group-item"><a href="https://github.com/gaborbsd/java_2ed_sources/archive/master.zip">Forráskódok</a>&#160;
                                    <a href="https://github.com/gaborbsd/java_2ed_sources" rel="noopener noreferrer" target="_blank"><span className="fa fa-github"/></a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <h4>Bónusz fejezetek&#160;<Tooltip
                                hint="Az előző kiadás fejezetei, amelyek a második kiadásba nem kerültek be.">
                                <span className="fa fa-question-circle"/>
                            </Tooltip></h4>

                            <ul className="list-group list-group-flush">
                                <li className="list-group-item"><a href="/java_2ed/chapter_jaxb.pdf">XML feldolgozása
                                    (JAXB)</a></li>
                                <li className="list-group-item"><a href="/java_2ed/chapter_jpa.pdf">Adatbázisok kezelése
                                    (JPA)</a></li>
                                <li className="list-group-item"><a href="/java_2ed/chapter_rmi.pdf">Hálózati
                                    kommunikáció
                                    (RMI)</a></li>
                                <li className="list-group-item"><a href="/java_2ed/chapter_swing.pdf">Grafikus
                                    felhasználói
                                    fejezet (Swing)</a></li>
                                <li className="list-group-item"><a href="https://github.com/gaborbsd/java_2ed_bonus_sources/archive/master.zip">Bónusz
                                    forráskódok</a>&#160;
                                    <a href="https://github.com/gaborbsd/java_2ed_bonus_sources" rel="noopener noreferrer" target="_blank"><span className="fa fa-github"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {this.state.orderFormVisible ?
                    <OrderForm ref={this.orderFormRef} onSuccessfulOrder={this.onSuccessfulOrder}/> : ""}

                {this.state.successfulOrder ? (
                    <div className="alert alert-success mt-2" role="alert">
                        A rendelésed sikeres volt, nemsokára visszaigazoló emailt kapsz.
                    </div>) : ""}
            </div>
        )
    }
}

export default Book;