import React, {Component} from "react";
import ReactDOM from "react-dom";

import TrainingForm from "../components/TrainingForm"

class Training extends Component {

    state = {
        trainingFormVisible: false,
        successfulSubscribe: false
    };

    constructor(props) {
        super(props);
        this.trainingFormRef = React.createRef();
    }

    showForm = () => {
        this.setState({
            trainingFormVisible: true,
            successfulSubscribe: false
        }, () => {
            const domNode = ReactDOM.findDOMNode(this.trainingFormRef.current);
            domNode.scrollIntoView();
        });
    };

    onSuccessfulOperation = () => {
        this.setState({
            trainingFormVisible: false,
            successfulSubscribe: true
        })
    };

    render() {
        return (
            <div id="courses" className="container bg-3 text-center">
                <div className="jumbotron">
                    <h1 className="display-5">Tanfolyamok</h1>
                    <p className="lead">Ha a könyvnél többre van szükséged, egyedi tanfolyamot, tanácsadást
                        ajánlok. Írd meg, mire van szükséged, és kérj személyre szabott árajánlatot!</p>
                    <p className="lead">
                        <button className="btn btn-primary btn-lg" onClick={this.showForm}>Ajánlatkérés</button>
                    </p>

                    {this.state.trainingFormVisible ?
                        <TrainingForm ref={this.trainingFormRef}
                                      onSuccessfulOperation={this.onSuccessfulOperation}/> : ""}

                    {this.state.successfulSubscribe ? (
                        <div className="alert alert-success mt-2" role="alert">
                            Az üzenetet sikeresen elküldted. Nemsokára felveszem veled a kapcsolatot.
                        </div>) : ""}
                </div>
            </div>
        )
    }
}

export default Training;