import React, {Component} from 'react';
import axios from 'axios';

class Login extends Component {


    state = {
        error: "",
        login: {
            value: "",
            isValid: true
        },
        password: {
            value: "",
            isValid: true
        }
    };

    inputChangeHandler = (event) => {
        const target = event.target;
        const value = target.value;
        const id = target.id;

        const state = this.state;
        state[id] = {
            value: value,
            isValid: true
        };

        this.setState({
            state
        });
    };

    validationHandler = (error) => {
        let state = this.state;
        state["error"] = "Login failed";
        state.login.isValid = false;
        state.password.isValid = false;
        this.setState(state);
    };

    submitForm = (event) => {
        event.preventDefault();
        let auth = {
            username: this.state.login.value,
            password: this.state.password.value
        };
        axios.get('/login', {
            auth: auth
        })
            .then((response) => {
                console.log(response.status);
                axios.defaults.auth = auth;
                localStorage.setItem("loggedIn", "true");
                this.props.history.push('/orders');
            })
            .catch((err) => {
                console.log(err.status);
                this.validationHandler(err.response);
            });
    };

    classString = (cond, base) => {
        return !cond ? base + " is-invalid" : base;
    };

    render() {
        return (
            <div className="row">
                <div className="col-sm-7 offset-sm-2 text-center">
                    <h3>Bejelentkezés</h3>
                    <form onSubmit={this.submitForm}>
                        <div className="form-group form-row">
                            <label htmlFor="login" className="col-sm-2 col-form-label">Login</label>
                            <input type="text"
                                   required={true}
                                   className={this.classString(this.state.login.isValid, "form-control col-sm-10")}
                                   id="login"
                                   placeholder="Login"
                                   value={this.state.login.value}
                                   onChange={this.inputChangeHandler}/>
                            <small className="form-text error-block">{this.state.login.message}</small>
                        </div>
                        <div className="form-group form-row">
                            <label htmlFor="password" className="col-sm-2 col-form-label">Jelszó</label>
                            <input type="password"
                                   required={true}
                                   className={this.classString(this.state.password.isValid, "form-control col-sm-10")}
                                   id="password"
                                   placeholder="Jelszó"
                                   value={this.state.password.value}
                                   onChange={this.inputChangeHandler}/>
                        </div>
                        <small
                            className="form-text error-block col-sm-12 text-left">{this.state.error}</small>
                        <button type="submit" className="btn btn-primary my-3">Bejelentkezés</button>
                    </form>
                </div>
            </div>
        )
    };
}

export default Login;
