import React, {Component} from "react";
import axios from "axios";

import DataProtectionNotice from "../common/DataProtectionNotice";

import Dashboard from "../util/Dashboard";
import Urls from "../util/Urls";

class NewsletterSubscribeForm extends Component {

    state = {
        contactName: {
            value: '',
            isValid: true,
            message: ''
        },
        contactEmail: {
            value: '',
            isValid: true,
            message: ''
        },
        gdprConsent: {
            value: false,
            isValid: true,
            message: ''
        },
        inProgress: false
    };

    inputChangeHandler = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        const state = this.state;
        state[id] = {
            value: value,
            isValid: true,
            message: ""
        };

        this.setState({
            state
        });
    };

    submitForm = (event) => {
        event.preventDefault();
        if (!this.props.captcha) {
            this.setState({captchaMessage: "A feliratkozás előtt töltsd ki a captchát!"});
            return;
        } else {
            this.setState({captchaMessage: ""});
        }
        this.setState({
            inProgress: true
        });
        const data = {};
        for (let formField in this.state) {
            if (this.state[formField].value !== undefined)
                data[formField] = this.state[formField].value;
        }
        console.log("Posting to /newsletter");
        console.log(data);
        const params = {};
        params["g-recaptcha-response"] = this.props.captcha;
        axios.post(Urls.NEWSLETTER_URL, data, {params: params})
            .then(resp => {
                console.log("Response:");
                console.log(resp);
                this.setState({
                    inProgress: false
                });
                this.props.onSuccessfulOperation();
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 400) {
                    console.log(error.response);
                    this.validationHandler(error.response.data);
                    this.setState({
                        inProgress: false
                    })
                } else {
                    this.setState(() => {
                        throw error;
                    })
                }
            });
    };

    validationHandler = (errors) => {
        const state = this.state;
        for (let fieldError of errors) {
            state[fieldError.field] = {
                value: this.state[fieldError.field].value,
                isValid: false,
                message: fieldError.message
            }
        }
        this.setState({
            state
        });
    };

    rejectGdpr = () => {
        const state = this.state;
        state["gdprConsent"] = {
            value: false,
            isValid: false,
            message: "A feliratkozáshoz hozzá kell járulni az adatok kezeléséhez"
        };
        this.setState(state);
    };

    acceptGdpr = () => {
        const state = this.state;
        state["gdprConsent"] = {
            value: true,
            isValid: true,
            message: ""
        };
        this.setState(state);
    };

    classString = (cond, base) => {
        return !cond ? base + " is-invalid" : base;
    };


    render() {
        return (
            <div className="col-sm-6">
                <h3>Feliratkozás</h3>
                <form onSubmit={this.submitForm}>
                    <div className="form-group form-row">
                        <label htmlFor="contactName" className="col-sm-2 col-form-label">Név</label>
                        <input type="text"
                               required={true}
                               className={this.classString(this.state.contactName.isValid, "form-control col-sm-10")}
                               id="contactName"
                               placeholder="Név"
                               value={this.state.contactName.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small className="form-text error-block">{this.state.contactName.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="contactEmail" className="col-sm-2 col-form-label">Email</label>
                        <input type="email"
                               required={true}
                               className={this.classString(this.state.contactEmail.isValid, "form-control col-sm-10")}
                               id="contactEmail"
                               placeholder="Email cím"
                               value={this.state.contactEmail.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small
                            className="form-text error-block col-sm-12 text-left">{this.state.contactEmail.message}</small>
                    </div>
                    <div className="form-check form-row">
                        <input type="checkbox"
                               required={true}
                               className={this.classString(this.state.gdprConsent.isValid, "form-check-input")}
                               id="gdprConsent"
                               checked={this.state.gdprConsent.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label className="form-check-label" htmlFor="gdprConsent">Hozzájárulok az adataim
                            kezeléséhez</label>
                        <Dashboard title="Adatkezelési tájékozató"
                                   onAccept={this.acceptGdpr}
                                   onReject={this.rejectGdpr}>
                            <DataProtectionNotice/>
                        </Dashboard>
                        <small className="form-text error-block">{this.state.gdprConsent.message}</small>
                    </div>
                    <button id="subscribe" type="submit" className="btn btn-primary my-3"
                            disabled={this.state.inProgress}>Feliratkozás
                    </button>
                    <small className="form-text error-block">{this.state.captchaMessage}</small>
                </form>
            </div>
        )
    }
}

export default NewsletterSubscribeForm;