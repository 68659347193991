import React from "react";
import format_localdate from "../util/java_localdate";
import Urls from "../util/Urls";



function OrderListItem(props) {
    return (<div className="card col-sm-6">
        <div className="card-body">
            <h5 className="card-title">{props.order.shippingName}</h5>
            <p className="card-text">{props.order.shippingStreet}<br/>
                {props.order.shippingCity}, {props.order.shippingZip}</p>
            <p className="card-text">{format_localdate(props.order.created)}</p>
            <h6 className="card-subtitle mb-2 text-muted">Számlázási cím</h6>
            <p className="card-text">{props.order.shippingName}<br/>
                {props.order.billingStreet}<br/>
                {props.order.billingCity}, {props.order.billingZip}</p>
            <h6 className="card-subtitle mb-2 text-muted">Fizetés</h6>
            <p className="card-text">{props.order.paymentType}</p>
            <p className="card-text">{props.order.quantity} db</p>
            <p className="card-text">{props.order.totalPrice} Ft</p>
            <a href={Urls.generate_wrapping_link(props.order.id)} className="card-link">Csomagolás</a>
            <a href={Urls.generate_deliv_note_link(props.order.id)} className="card-link">Feladóvevény</a><br/>
            <button className="btn btn-success mt-3">Postázva</button>
        </div>
    </div>);
}

export default OrderListItem;