import React, {Component} from 'react';
import axios from 'axios';
import OrderListItem from "../components/OrderListItem";

class Orders extends Component {

    state = {
        orders: []
    };

    componentDidMount() {
        axios.get("/orders/new")
            .then((response) => {
                console.log(response.data);
                this.setState({orders: response.data});
            })
            .catch((error) => {

            })
    }

    render() {
        return (<div className="row">
                {this.state.orders.map(o => <OrderListItem key={o.id} order={o}/>)}
                </div>
        )
    };
}

export default Orders;
