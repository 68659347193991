import React from "react";
import {Link} from "react-router-dom";

import decypher from "../util/decipher";

function Footer(props) {
    return (
        <footer className="bg-light text-center text-muted mt-5">
            Copyright © 2018-2019 Kövesdán Gábor &lt;<a
            href={"mailto:" + decypher("jogpAkbwbplubup/dpn")}>{decypher("jogpAkbwbplubup/dpn")}</a>&gt; | <Link
            to="/gdpr">Adatkezelési tájékoztató</Link>
        </footer>
    );
}

export default Footer;