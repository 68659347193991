import React, {Component} from "react";
import ReCAPTCHA from "react-google-recaptcha";

import NewsletterSubscribeForm from "../components/NewsletterSubscribeForm"
import NewsletterUnsubscribeForm from "../components/NewsletterUnsubscribeForm"

class Newsletter extends Component {

    state = {
        successfulSubscribe: false,
        successfulUnsubscribe: false,
        captcha: false
    };

    onSuccessfulSubscribe = () => {
        if (this.captchaRef)
            this.captchaRef.reset();
        this.setState({
            successfulSubscribe: true,
            successfulUnsubscribe: false
        })
    };

    onSuccessfulUnsubscribe = () => {
        if (this.captchaRef)
            this.captchaRef.reset();
        this.setState({
            successfulSubscribe: false,
            successfulUnsubscribe: true
        })
    };

    onCaptchaValidation = (value) => {
        this.setState({captcha: value})
    };

    render() {
        return (
            <div className="container">
                <div className="row text-center">
                    <NewsletterSubscribeForm onSuccessfulOperation={this.onSuccessfulSubscribe}
                                             captcha={this.state.captcha}/>
                    <NewsletterUnsubscribeForm onSuccessfulOperation={this.onSuccessfulUnsubscribe}
                                               captcha={this.state.captcha}/>
                </div>

                <div className="row text-center">
                    {this.state.successfulSubscribe ? (
                        <div className="col-sm-12 text-center alert alert-success mt-2" role="alert">
                            Sikeres feliratkozás
                        </div>) : ""}
                    {this.state.successfulUnsubscribe ? (
                        <div className="col-sm-12 text-center alert alert-success mt-2" role="alert">
                            Sikeres leiratkozás.
                        </div>) : ""}
                </div>

                <div className="captcha-container">
                    <ReCAPTCHA
                        ref={(el) => {
                            this.captchaRef = el;
                        }}
                        sitekey="6LepJ3EUAAAAANS7dop2_jpbYKOs_cLhx1qVHMTZ"
                        onChange={this.onCaptchaValidation}/>
                </div>
            </div>
        )
    }
}

export default Newsletter;