import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";

class Nav extends Component {

    classString = (path, classes) => {
        return (this.props.location.pathname === path) ?
            "active " + classes : classes;
    };

    render() {
        return (
            <nav className="navbar navbar-expand-sm bg-dark navbar-dark fixed-top">
                <Link className="navbar-brand" to="/">A Java oktató</Link>
                <div className="nav navbar-nav">
                    <Link className={this.classString("/java_2ed", "nav-item nav-link")} to="/java_2ed">Könyv</Link>
                    <Link className={this.classString("/newsletter", "nav-item nav-link")}
                          to="/newsletter">Hírlevél</Link>
                    <Link className={this.classString("/training", "nav-item nav-link")}
                          to="/training">Tanfolyamok</Link>
                </div>
            </nav>
        )
    }
}

export default withRouter(Nav);