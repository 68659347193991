import React from "react";

import decypher from "../util/decipher";

function DataProtectionNotice() {
    return (<div>
            <h4>1. Az adatkezelési tájékoztató célja</h4>

            <p>Jelen dokumentum célja, hogy Kövesdán Gábor ev.,
                mint adatkezelő (a továbbiakban egységesen Adatkezelő) leírja az Adatkezelő
                szervezetében alkalmazott és működő, személyes adatnak
                minősülő adatokra vonatkozó adatvédelmi szabályokat, eljárásokat és
                védelmi intézkedéseket.</p>

            <p>Az Adatkezelő egyúttal e dokumentumban tájékoztatja
                ügyfeleit, partnereit, illetőleg minden olyan természetes- és jogi
                személyt, akik az Adatkezelővel bármilyen - jogi szempontból
                értelmezhető - kapcsolatban állnak és a személyes kezelése során
                érintettségük keletkezik, az általa kezelt személyes adatok kezelésének
                szabályairól, az alkalmazott védelmi intézkedésekről eljárásokról és az
                adatkezelés módjáról.</p>

            <p>Az Adatkezelő jelen Adatkezelési
                tájékoztatóban leírt szabályokat, rendelkezéseket és kötelezettségeket saját
                magára jogilag kötelező érvényűnek tekinti, és működése során
                alkalmazza, valamint kijelenti, hogy jelen dokumentumban leírt és alkalmazott
                adatvédelmi szabályok és eljárások megfelelnek a hatályos adatvédelmi nemzeti
                és Európai Uniós jogszabályoknak. Adatkezelő kijelenti továbbá, hogy
                fontosnak tekinti az információs önrendelkezés jogát, különös tekintettel a
                személyes adatokra és saját hatókörében minden elérhető szervezeti,
                működési, szabályozási és technológia intézkedést megtesz e jogok
                betartása és betartatása érdekében.</p>

            <p>Az Adatkezelési tájékoztató mindenkor hatályos
                verziója a <a href="http://javaoktato.com/gdpr">
                    http://javaoktato.com/gdpr</a> webcímen érhető el. Az Adatkezelési
                tájékoztatót Adatkezelő bármikor megváltoztathatja, a közzététel- és az Érintettek
                tájékoztatásának kötelezettsége
                mellett.</p>

            <h4>2. Az Adatkezelő adatai</h4>

            <h5>2.1 Az Adatkezelő adatai</h5>

            <ul>
                <li>Név: Kövesdán Gábor ev.</li>

                <li>Anyja neve: Balogh Judit</li>

                <li>Székhely: Budapest 1043, Mártírok útja 58. A lh. 3.
                    em. 3.
                </li>

                <li>Nyilvántartási szám: 51014513</li>

                <li>Adószám: 67964792141</li>

                <li>Központi e-mail: <a
                    href={"mailto:" + decypher("jogpAkbwbplubup/dpn")}>{decypher("jogpAkbwbplubup/dpn")}</a></li>
            </ul>
            <h5>2.2. Az Adatkezelő elérhetőségei</h5>

            <p>Adatvédelmi kérdésekben Adatkezelő az alábbi
                elérhetőségeken kereshető meg:</p>

            <ul>
                <li>Email: <a href={"mailto:" + decypher("jogpAkbwbplubup/dpn")}>{decypher("jogpAkbwbplubup/dpn")}</a>
                </li>

                <li>Levelezési cím: Budapest 1043, Mártírok útja 58. A
                    lh. 3. em. 3.
                </li>

            </ul>
            <p>Az Adatkezelő a hozzá beérkezett adatvédelmi
                megkereséseket (e-mail) a 3.
                pontban, az adott adatkezelésre vonatkozó, meghatározott ideig őrzi meg.
                Ennek leteltével ezek visszavonhatatlan módon törlésre kerülnek.</p>

            <h4>3. Adatkezelő Adatkezelései és a kezelt személyes
                adatok</h4>

            <h5>3.1. Hírlevél feliratkozás során megadandó személyes adatok</h5>

            <ul>
                <li>Az adatkezelés jogalapja: a GDPR 6. cikk (1) bek. a) pontjának megfelelően az Érintett önkéntes
                    hozzájárulása
                </li>

                <li>Az adatkezelés célja: ingyenes szakmai anyagok,
                    promóciós ajánlatok küldése
                </li>

                <li>Az adatszolgáltatás elmaradásának lehetséges
                    következményei: az adatkezelés hiányában a szolgáltatás nyújtása nem lehetséges
                </li>

                <li>Az adatkezelésben kezelt adatok:
                    <ul>
                        <li>Név</li>

                        <li>Email cím</li>
                    </ul></li>
                <li>Az adatkezelésben kezelt adatok megőrzési
                    ideje: az Érintett törlési kérelméig
                </li>
            </ul>

            <h5>3.2 Online rendeléshez kapcsolódó adatok</h5>

            <ul>
                <li>Az adatkezelés jogalapja: a GDPR 6. cikk (1) bek. a) pontjának megfelelően az Érintett önkéntes
                    hozzájárulása, valamint a 2000. évi C. törvény (Számv. tv.) 169. § (2) bekezdése
                </li>

                <li>Az adatkezelés célja: a vásárlásról számla kiállítása,
                    a megrendelések teljesítése, a vásárlás és fizetés dokumentálása, számviteli
                    kötelezettség teljesítése
                </li>

                <li>Az adatszolgáltatás elmaradásának lehetséges
                    következményei: az adatkezelés hiányában a szolgáltatás nyújtása nem lehetséges
                </li>

                <li>Az adatkezelésben kezelt adatok:
                    <ul>
                        <li>Név</li>

                        <li>Email cím</li>

                        <li>Telefonszám</li>

                        <li>Szállítási cím</li>

                        <li>Számlázási név</li>

                        <li>Számlázási cím</li>
                    </ul></li>

                <li>Az adatkezelésben kezelt adatok megőrzési
                    ideje: a Számv. tv. 169. § (2) bekezdése alapján nyolc év
                </li>
            </ul>
            <h5>3.3. Tanfolyami ajánlatkéréshez kapcsolódó adatok</h5>

            <ul>
                <li>Az adatkezelés jogalapja: a GDPR 6. cikk (1) bek. a) pontjának megfelelően
                    az Érintett önkéntes hozzájárulása
                </li>

                <li>Az adatkezelés célja: az ajánlatkérések és a
                    későbbi tanfolyami megrendelések nyomon követése és dokumentálása
                </li>

                <li>Az adatszolgáltatás elmaradásának lehetséges
                    következményei: az adatkezelés hiányában a szolgáltatás nyújtása nem lehetséges
                </li>

                <li>Az adatkezelésben kezelt adatok:
                    <ul>
                        <li>Név</li>

                        <li>Email cím</li>

                        <li>Telefonszám</li>
                    </ul></li>
                <li>Az adatkezelésben kezelt adatok megőrzési
                    ideje: az Érintett törlési kérelméig
                </li>
            </ul>

            <h5>3.4. Technikai adatok</h5>

            <p>Technikai adatok, azon adatok, amelyek az
                Adatkezelő rendszereinek működése során többnyire automatikusan
                keletkeznek és kerülnek rögzítésre. Egyes technikai adatokat a rendszer, az
                Érintett külön nyilatkozata, vagy cselekménye nélkül is tárol és bizonyos
                esetekben automatikusan naplóz. A technikai adatok az Érintett személyének azonosítására
                közvetlenül nem alkalmasak, azonban összekapcsolhatók felhasználói adatokkal,
                így az azonosítás elvileg lehetővé válik. Ilyen adatkapcsolatokat az
                Adatkezelő nem készít, kivéve egyes olyan esetekben, amikor erre
                Adatkezelőt törvény kötelezi. A technikai adatokhoz kizárólag az
                Adatkezelő és Adatfeldolgozói férhetnek hozzá.</p>

            <ul>
                <li>Az adatkezelés jogalapja: a GDPR 6. cikk (1) f)
                    pontja szerint az Adatkezelő jogos érdeke
                </li>

                <li>Az adatkezelés célja: a weboldalt ért támadási
                    kísérletek utólagos nyomon követhetősége
                </li>

                <li>Az adatszolgáltatás elmaradásának lehetséges
                    következményei: az esetleges visszaélések, támadási kísérletek nem lennének
                    könnyen visszakereshető
                </li>

                <li>Az adatkezelésben kezelt adatok:
                    <ul>
                        <li>IP-cím</li>
                        <li>Hosztnév</li>
                    </ul></li>
                <li>Az adatkezelésben kezelt adatok megőrzési
                    ideje: 2 év
                </li>
            </ul>

            <h4>4. Az adatkezelés célja, módja és jogalapja</h4>

            <h5>4.1. Általános adatkezelési irányelvek</h5>

            <p>Adatkezelő a 3. pontban felsorolt Adatkezelésekben a személyes
                adatok kezelését, minden esetben az adatkezelésben megadott céllal és az ott
                megadott jogalap alapján, a 4.2.
                pontban felsorolt jogszabályok szerint végzi. </p>

            <p>A személyes adatok kezelése minden esetben az
                Érintett önkéntes hozzájárulásával történik, amely hozzájárulást az Érintett
                bármikor jogosult visszavonni.</p>

            <p>Adatkezelő egyes személyes adatokat,
                jogszabályi kötelezettség folytán, bizonyos esetekben és bizonyos rendhagyó
                feltételek esetén, köteles az Adatkezelésekben leírtaktól eltérő módon
                kezelni, átadni, továbbítani, tárolni. Ilyen esetekben Adatkezelő
                gondoskodik az Érintettek értesítéséről, amennyiben az adott jogszabály
                előírásai ezt lehetővé teszik, vagy nem tiltják kifejezetten.</p>

            <h5>4.2. Az Adatkezelések jogalapját adó jogszabályok</h5>

            <p>Adatkezelő a személyes adatok kezelését az
                alábbi jogszabályok alapján végzi:</p>
            <ul>
                <li>GDPR Az Európai Parlament és a Tanács (EU)
                    2016/679 rendelete
                </li>

                <li>2000. évi C. törvény - a számvitelről
                    (Számv. tv.)
                </li>
            </ul>
            <h4>5. Az adatok tárolása és biztonsága</h4>

            <h5>5.1. Az adatok fizikai tárolási helyei</h5>

            <p>Adatkezelő a személyes adatokat integrált
                informatikai rendszerén tárolja. A rendszer elemei a következő földrajzi,
                fizikai helyeken vannak elhelyezve:</p>

            <table className="table">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">Meghatározás</th>
                    <th scope="col">Cím</th>
                    <th scope="col">Leírás</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">Adatkezelő székhelye</th>
                    <td>Budapest 1043, Mártírok útja 58. A lh. 3.em. 3.</td>
                    <td>Kiállított számlák</td>
                </tr>
                <tr>
                    <th scope="row">Webszerver</th>
                    <td>Budapest 1132, Victor Hugo u. 18-22.</td>
                    <td>Adatbázis</td>
                </tr>
                </tbody>
            </table>

            <h5>5.2. Az adatok informatikai tárolásának módja és logikai biztonsága</h5>

            <p>Adatkezelő a személyes adatok kezelését
                elsődlegesen megfelelően kiépített és védett informatikai rendszerén
                végzi. Az informatikai rendszer üzemeltetése során gondoskodik az azon tárolt,
                feldolgozott és továbbított adatok információbiztonsági alapattribútumainak
                megfelelő szintjéről, úgymint a kezelt adatok:</p>

            <ul>
                <li>sértetlenségéről (Integrity), az adat
                    eredetisége, változatlansága biztosított;
                </li>

                <li>bizalmasságáról (Confidentality), kizárólag az arra
                    jogosultak, a jogosultságukat nem meghaladó mértékben férnek hozzá;
                </li>

                <li>rendelkezésre állásáról (Availability), az adat a
                    jogosultak számára hozzáférhető és elérhető, az elvárt rendelkezésre
                    állási időtartamban. A szükséges informatikai infrastruktúra üzemkészen
                    rendelkezésre áll.
                </li>

                <li>Adatkezelő a kezelt adatokat:</li>

                <li>szervezeti, működési,</li>

                <li>fizikai biztonsági,</li>

                <li>információbiztonsági</li>

                <li>védelmi intézkedések strukturált rendszerével védi.
                    Adatkezelő a védelmi intézkedéseket rendszerét és az egyes védelmi
                    intézkedések védelmi szintjeit, a védendő adatok fenyegetéseinek hatására
                    felmerülő kockázatokkal arányos mértékben alakítja ki és működteti. A
                    védelmi intézkedések adatvédelmi szempontból elsősorban a véletlen vagy
                    szándékos törlés, jogosulatlan hozzáférés, szándékos és rosszhiszemű
                    nyilvánosságra hozatal, véletlen nyilvánosságra kerülés, adatvesztés,
                    adatmegsemmisülés elleni védelmet célozzák.
                </li>
            </ul>

            <h4>6. Adattovábbítás, adatfeldolgozás, az adatokat megismerők köre</h4>

            <p>Az adatokat elsődlegesen az Adatkezelő, illetve
                belső munkatársai jogosultak megismerni, a jogosultsági szabályoknak, a
                jogosultsági rendszernek és az egyéb belső szabályozásoknak megfelelő
                mértékben. Az Adatkezelő egyes adatokkal kapcsolatos műveleteket,
                feladatokat, harmadik fél Adatfeldolgozókkal végeztet el. Az Adatkezelő az
                adatokat a felsoroltakon túl nem teszi közzé és egyéb harmadik személyeknek nem
                adja át.</p>

            <table className="table">
                <thead className="thead-dark">
                <tr>
                    <th scope="col">Név</th>
                    <th scope="col">Cím</th>
                    <th scope="col">Tevékenység</th>
                    <th scope="col">Szerepkör</th>
                    <th scope="col">Átadott adatok</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th scope="row">Gigor Tünde ev.</th>
                    <td>Budapest 1141, Gödöllői u. 163.</td>
                    <td>Könyvelés</td>
                    <td>Adatfeldolgozó</td>
                    <td>Számlázási név, számlázási cím</td>
                </tr>
                <tr>
                    <th scope="row">Magyar Posta Zrt.</th>
                    <td>Budapest 1138, Dunavirág utca 2-6.</td>
                    <td>Kézbesítés</td>
                    <td>Adatfeldolgozó</td>
                    <td>Szállítási név, szállítási cím, email, telefonszám</td>
                </tr>
                </tbody>
            </table>

            <h4>7. Érintett jogai</h4>

            <p>Az Érintett az Adatkezelő által kezelt
                személyes adataival kapcsolatosan – többek között – a következőkben leírt
                jogokkal élhet.</p>

            <h5>7.1. Az Érintett hozzáféréshez való joga (GDPR 15. cikk)</h5>

            <p>Az Érintett jogosult arra, hogy az
                adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy személyes
                adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van,
                jogosult arra, hogy a személyes adatokhoz és a következő információkhoz
                hozzáférést kapjon:</p>

            <ul>
                <li>az adatkezelés céljai;</li>

                <li>az Érintett személyes adatok kategóriái;</li>

                <li>azon címzettek vagy címzettek kategóriái, akikkel,
                    illetve amelyekkel a személyes adatokat közölték vagy közölni fogják, ideértve
                    különösen a harmadik országbeli címzetteket, illetve a nemzetközi
                    szervezeteket;
                </li>

                <li>a személyes adatok tárolásának tervezett
                    időtartama;
                </li>

                <li>az Érintett joga a helyesbítéshez, törléshez vagy
                    adatkezelés korlátozásához, valamint az adatkezelés elleni tiltakozáshoz;
                </li>

                <li>panasz benyújtásának joga a felügyeleti hatósághoz;</li>

                <li>ha az adatokat nem az Érintettől
                    gyűjtötték, a forrásukra vonatkozó minden elérhető információ;
                </li>

                <li>az automatizált döntéshozatal ténye, ideértve a
                    profilalkotást is, valamint az alkalmazott logikára és arra vonatkozó
                    érthető információk, hogy az ilyen adatkezelés milyen jelentőséggel
                    bír, és az Érintettre nézve milyen várható következményekkel jár.
                </li>

                <li>Az Adatkezelő az adatkezelés tárgyát
                    képező személyes adatok másolatát 1 példányban az Érintett rendelkezésére
                    bocsátja. Az Érintett által kért további másolatokért az Adatkezelő az
                    adminisztratív költségeken alapuló, észszerű mértékű díjat számíthat
                    fel. Ha az Érintett elektronikus úton nyújtotta be a kérelmet, Adatkezelő az
                    információkat széles körben használt elektronikus formátumban adja át, kivéve ha
                    az Érintett másként kéri, a benyújtástól számított legfeljebb 30 napon belül.
                </li>
            </ul>

            <h5>7.2. Helyesbítés joga (GDPR 16. cikk)</h5>

            <p>Az Érintett jogosult arra, hogy kérésére az Adatkezelő
                indokolatlan késedelem nélkül helyesbítse a rá vonatkozó pontatlan személyes
                adatokat, valamint jogosult arra, hogy kérje a hiányos személyes adatok
                kiegészítését, figyelemmel véve az adatkezelés céljára.</p>

            <h5>7.3. Törléshez való jog (GDPR 17. cikk)</h5>

            <p>Az Érintett jogosult arra, hogy kérésére az Adatkezelő
                indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat, az Adatkezelő
                pedig köteles arra, hogy az Érintettre vonatkozó személyes adatokat
                indokolatlan késedelem nélkül törölje, ha az alábbi indokok valamelyike
                fennáll: </p>

            <ul>
                <li>személyes adatokra már nincs szükség abból a
                    célból, amelyből azokat gyűjtötték vagy más módon kezelték;
                </li>

                <li>az Érintett visszavonja az adatkezelés alapját
                    képező hozzájárulását, és az adatkezelésnek nincs más jogalapja;
                </li>

                <li>az Érintett tiltakozik az adatkezelés ellen, és
                    nincs elsőbbséget élvező jogszerű ok az adatkezelésre;
                </li>

                <li>a személyes adatokat jogellenesen kezelték;</li>

                <li>a személyes adatokat az Adatkezelőre
                    alkalmazandó uniós vagy tagállami jogban előírt jogi kötelezettség
                    teljesítéséhez törölni kell;
                </li>

                <li>a személyes adatok gyűjtésére információs
                    társadalommal összefüggő szolgáltatások kínálásával kapcsolatosan került
                    sor.
                </li>
            </ul>

            <p>Az adatok törlése nem kezdeményezhető, ha az
                adatkezelés szükséges:</p>

            <ul>
                <li>a véleménynyilvánítás szabadságához és a
                    tájékozódáshoz való jog gyakorlása céljából;
                </li>

                <li>a személyes adatok kezelését előíró, az Adatkezelőre
                    alkalmazandó uniós vagy tagállami jog szerinti kötelezettség teljesítése,
                    illetve közérdekből, vagy az Adatkezelőre ruházott közhatalmi
                    jogosítvány gyakorlása keretében végzett feladat végrehajtása céljából;
                </li>

                <li>a népegészségügy területét érintő közérdek
                    alapján;
                </li>

                <li>Közérdek alapján végzendő archiválási-,
                    tudományos és történelmi kutatási-, vagy statisztikai célból;
                </li>

                <li>jogi igények előterjesztéséhez, érvényesítéséhez,
                    illetve védelméhez.
                </li>
            </ul>

            <h5>7.4. Az adatkezelés korlátozásához való jog (GDPR 18. cikk)</h5>

            <p>Az Érintett kérésére a Adatkezelő korlátozza
                az adatkezelést, ha az alábbi feltételek valamelyike teljesül: </p>

            <ul>
                <li>az Érintett vitatja a személyes adatok pontosságát,
                    ez esetben a korlátozás arra az időtartamra vonatkozik, amely
                    lehetővé teszi, hogy az Adatkezelő ellenőrizze a személyes
                    adatok pontosságát;
                </li>

                <li>az adatkezelés jogellenes, és az Érintett ellenzi
                    az adatok törlését, és ehelyett kéri azok felhasználásának korlátozását;
                </li>

                <li>az Adatkezelőnek már nincs szüksége a
                    személyes adatokra adatkezelés céljából, de az Érintett igényli azokat jogi
                    igények előterjesztéséhez, érvényesítéséhez vagy védelméhez; vagy
                </li>

                <li>az Érintett tiltakozott az adatkezelés ellen; ez
                    esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra
                    nem kerül, hogy az Adatkezelő jogos indokai elsőbbséget élveznek-e az
                    Érintett jogos indokaival szemben.
                </li>
            </ul>

            <p>Ha az adatkezelés korlátozás alá esik, a személyes
                adatokat a tárolás kivételével csak az Érintett hozzájárulásával, vagy jogi
                igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más
                természetes vagy jogi személy jogainak védelme érdekében, vagy az Unió, illetve
                valamely tagállam fontos közérdekéből lehet kezelni.</p>

            <h5>7.5. Adathordozáshoz való jog (GDPR 20. cikk)</h5>

            <p>Az Érintett jogosult arra, hogy a rá vonatkozó,
                általa az Adatkezelő rendelkezésére bocsátott személyes adatokat tagolt,
                széles körben használt, géppel olvasható formátumban megkapja, és ezeket az
                adatokat egy másik Adatkezelőnek továbbítsa. </p>

            <h5>7.6. Tiltakozás joga (GDPR 21. cikk)</h5>

            <p>Az Érintett jogosult arra, hogy a saját helyzetével
                kapcsolatos okokból bármikor tiltakozzon személyes adatainak kezelése ellen,
                ideértve az említett rendelkezéseken alapuló profilalkotást is. Ebben az
                esetben az Adatkezelő a személyes adatokat nem kezelheti tovább, kivéve,
                ha az Adatkezelő bizonyítja, hogy az adatkezelést olyan kényszerítő
                erejű jogos okok indokolják, amelyek elsőbbséget élveznek az Érintett
                érdekeivel, jogaival szemben, vagy amelyek jogi igények előterjesztéséhez,
                érvényesítéséhez vagy védelméhez kapcsolódnak.</p>

            <h5>7.7. Automatizált döntéshozatal egyedi ügyekben,
                beleértve a profilalkotást (GDPR 22.cikk)</h5>

            <p>Az Érintett jogosult arra, hogy ne terjedjen ki rá
                az olyan, kizárólag automatizált adatkezelésen – ideértve a profilalkotást is –
                alapuló döntés hatálya, amely rá nézve joghatással járna vagy őt
                hasonlóképpen jelentős mértékben érintené. </p>

            <h5>7.8. Visszavonás joga</h5>

            <p>Az Érintett jogosult arra, hogy személyes adataira
                vonatkozó hozzájárulását bármikor visszavonja.</p>

            <h5>7.9. Jogorvoslati lehetőségek</h5>

            <p>Jogai megsértése esetén az Érintett a 2.2. pontban megadott elérhetőségeken
                keresztül kérhet tájékoztatást, jogorvoslatot,
                vagy tehet panaszt. A Ezek eredménytelensége esetén Érintett jogosult bírósághoz
                fordulni, illetve megkeresni a Nemzeti Adatvédelmi és Információszabadság
                Hatóságot.</p>

            <h5>7.10. Nemzeti Adatvédelmi és Információszabadság Hatóság (NAIH)
                elérhetősége</h5>

            <ul>
                <li>Megnevezés: Nemzeti Adatvédelmi és
                    Információszabadság Hatóság (NAIH)
                </li>

                <li>Székhely: 1125 Budapest, Szilágyi Erzsébet fasor
                    22/C.
                </li>

                <li>Levelezési cím: 1530 Budapest, Pf.: 5.</li>

                <li>Tel: +36 (1)&nbsp;391-1400</li>


                <li>Fax: +36 (1)&nbsp;391-1410</li>

                <li>E-mail: <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a></li>

                <li>Honlap: <a
                    href="http://www.naih.hu">http://www.naih.hu</a></li>

            </ul>

            <h4>8. Egyéb rendelkezések</h4>

            <p>Hatósági megkeresés, vagy egyéb jogszabályi kötelezettségen
                alapuló egyéb szervezet megkeresése esetén Adatkezelő adatkiadásra lehet
                köteles, vagy arra lehet kötelezhető. Ilyen esetekben Adatkezelő
                törekszik csak annyi és olyan jellegű személyes adat kiadására, amely az
                adatkiadási kötelezettség szempontjából feltétlenül szükséges.</p>

        </div>
    );
}

export default DataProtectionNotice;