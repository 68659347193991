import React from "react";

function About() {
    return (
        <div className="container bg-3 text-center">
            <div className="row">
                <div className="col-md-4">
                    <img src="gaborkovesdan.jpg" className="img-fluid rounded-circle" alt="Kövesdán Gábor fotója"/>
                </div>
                <div className="col-md-8">
                    <h4>A Java oktató</h4>
                    <p>Kövesdán Gábor vagyok, okleveles mérnökinformatikus. A Budapesti Műszaki
                        és Gazdaságtudományi Egyetemen végeztem 2013-ban az Alkalmazott
                        Informatika szakirányon. Már tanulmányaim során dolgoztam
                        projektmunkákon, illetve nyílt forrású szoftvereken.
                        2006 óta foglalkozom szoftverfejlesztéssel, és 2008 óta a Java nyelvvel.
                        Rendelkezem az Oracle által kiadott összes Java SE és Java EE minősítéssel,
                        valamint a MySQL adatbáziskezelő-rendszer üzemeltetéséről és fejlesztői
                        felhasználásáról is szereztem Oracle minősítést. 2006 óta tagja vagyok a
                        FreeBSD nyílt forráskódú operációs rendszer fejlesztői csapatának.
                        A projektben többféle területen dolgoztam, ezek közül kiemelném a dokumentáció és az
                        előállításhoz felhasznált eszközök fejlesztését. A munkámnak köszönhetően
                        2012 júniusában meghívást kaptam a Documentation Engineering Teambe. Ez a
                        csoport a dokumentációkészítésben és az ehhez használt XML-alapú
                        technológiák használatában jártas szakemberekből áll. Ők felügyelik a FreeBSD
                        dokumentációjának fejlődését. A könyvírás iránti motiváció is a könyvek és a
                        dokumentáció szeretetéből adódott. 2013-ban egy szakíróképzésen is részt vettem,
                        utána született meg a Java-könyvem első kiadása.</p>

                    <p>A tanulmányaim után az Automatizálási és Alkalmazott Informatikai
                        Tanszéken jelentkeztem doktori képzésre, itt 2013 óta oktatok.
                        Jelenleg is itt tanítok tanársegédként, tananyagokat és jegyzeteket készítek,
                        részt veszek kutatási és fejlesztési projektekben,
                        valamint doktori értekezésemen dolgozom. Könyvemet hallgatóimnak és
                        minden érdeklődőnek ajánlom.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <iframe className="acclaim-badge"
                            frameBorder="0"
                            scrolling="no"
                            src="https://www.youracclaim.com/embedded_badge/90950447-3a22-4b1e-87e9-ae585bc2bae3"
                            title="View my verified achievement on Acclaim."/>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <iframe className="acclaim-badge"
                            frameBorder="0"
                            scrolling="no"
                            src="https://www.youracclaim.com/embedded_badge/f1341ec9-4fb3-47de-8401-611ada6512e4"
                            title="View my verified achievement on Acclaim."/>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <iframe className="acclaim-badge"
                            frameBorder="0"
                            scrolling="no"
                            src="https://www.youracclaim.com/embedded_badge/9316d268-887a-47cc-8ca9-e739630ecc8b"
                            title="View my verified achievement on Acclaim."/>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <iframe className="acclaim-badge"
                            frameBorder="0"
                            scrolling="no"
                            src="https://www.youracclaim.com/embedded_badge/12bdd857-24b1-4b41-9389-5e26416ca471"
                            title="View my verified achievement on Acclaim."/>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <iframe className="acclaim-badge"
                            frameBorder="0"
                            scrolling="no"
                            src="https://www.youracclaim.com/embedded_badge/7d40487a-59bf-4d93-9d75-36b77751cfd2"
                            title="View my verified achievement on Acclaim."/>
                </div>
            </div>
        </div>
    )
}

export default About;