import React, {Component} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import DataProtectionNotice from "../common/DataProtectionNotice";

import PriceSummary from "./PriceSummary";

import Dashboard from "../util/Dashboard";
import Tooltip from "../util/Tooltip";
import Urls from "../util/Urls";

class OrderForm extends Component {

    state = {
        order: {
            quantity: {
                value: '1',
                isValid: true,
                message: ''
            },
            couponCode: {
                value: '',
                isValid: true,
                message: ''
            },
            paymentType: {
                value: 'TRANSFER',
                isValid: true,
                message: ''
            },
            shippingName: {
                value: '',
                isValid: true,
                message: ''
            },
            shippingEmail: {
                value: '',
                isValid: true,
                message: ''
            },
            shippingPhone: {
                value: '',
                isValid: true,
                message: ''
            },
            shippingCity: {
                value: '',
                isValid: true,
                message: ''
            },
            shippingZip: {
                value: '',
                isValid: true,
                message: ''
            },
            shippingStreet: {
                value: '',
                isValid: true,
                message: ''
            },
            hasBillingAddress: {
                value: '',
                isValid: false,
                message: ''
            },
            billingName: {
                value: '',
                isValid: true,
                message: ''
            },
            billingCity: {
                value: '',
                isValid: true,
                message: ''
            },
            billingZip: {
                value: '',
                isValid: true,
                message: ''
            },
            billingStreet: {
                value: '',
                isValid: true,
                message: ''
            },
            gdprConsent: {
                value: false,
                isValid: true,
                message: ''
            },
            paymentConsent: {
                value: false,
                isValid: true,
                message: ''
            },
            mailingListConsent: {
                value: false,
                isValid: true,
                message: ''
            }
        },
        price: {
            orderPrice: 0,
            codCost: 0,
            shippingCost: 0,
            shippingType: "PO_LETTER"
        },
        captcha: null,
        captchaMessage: null
    };

    componentDidMount() {
        this.calculatePrice();
    }

    getOrder = () => {
        const order = {};
        for (let formField in this.state.order) {
            order[formField] = this.state.order[formField].value;
        }
        if (!this.state.order.hasBillingAddress.value) {
            order["billingName"] = order["shippingName"];
            order["billingCity"] = order["shippingCity"];
            order["billingZip"] = order["shippingZip"];
            order["billingStreet"] = order["shippingStreet"];
        }
        return order;
    };

    inputChangeHandler = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        if (id === "hasBillingAddress" && value) {
            const billingFields = ["billingName", "billingCity", "billingZip", "billingStreet"];

            const order = this.state.order;
            for (let key of billingFields) {
                order[key] = {
                    value: "",
                    isValid: true,
                    message: ""
                }
            }
            this.setState({
                order: order
            });
        }
        const order = this.state.order;
        order[id] = {
            value: value,
            isValid: true,
            message: ""
        };

        const needPriceUpdate = id === "quantity" || id === "paymentType";
        const callback = () => {
            if (needPriceUpdate)
                this.calculatePrice();
        };


        this.setState({
            order: order
        }, callback);
    };

    calculatePrice = () => {
        const order = this.getOrder();
        if (order.quantity < 1) {
            this.setState({
                price: {
                    orderPrice: 0,
                    shippingCost: 0
                }
            });
            return;
        }
        console.log("Posting to /orders/price");
        console.log(order);
        axios.post(Urls.PRICE_URL, order)
            .then(resp => {
                console.log("Response:");
                console.log(resp);
                const statePatch = {};
                statePatch["price"] = resp.data;
                this.setState(statePatch);
            })
            .catch(error => {
                this.setState(() => {
                    throw error;
                });
            });
    };

    submitForm = (event) => {
        event.preventDefault();
        if (!this.state.captcha) {
            this.setState({captchaMessage: "A rendelés előtt töltsd ki a captchát!"});
            return;
        } else {
            this.setState({captchaMessage: ""});
        }
        this.setState({
            inProgress: true
        });
        const order = this.getOrder();
        console.log("Posting to /orders");
        console.log(order);
        const params = {};
        params["g-recaptcha-response"] = this.state.captcha;
        axios.post(Urls.ORDER_URL, order, {params: params})
            .then(resp => {
                console.log("Response:");
                console.log(resp);
                this.props.onSuccessfulOrder();
            })
            .catch(error => {
                if (error.response.status === 400) {
                    console.log(error.response);
                    this.validationHandler(error.response.data);
                    this.setState({
                        inProgress: false
                    })
                } else {
                    this.setState(() => {
                        throw error;
                    })
                }
            });
    };

    validationHandler = (errors) => {
        const order = this.state.order;
        for (let fieldError of errors) {
            order[fieldError.field] = {
                value: this.state.order[fieldError.field].value,
                isValid: false,
                message: fieldError.message
            }
        }
        this.setState({
            order: order
        });
    };

    rejectGdpr = () => {
        const order = this.state.order;
        order["gdprConsent"] = {
            value: false,
            isValid: false,
            message: "A rendeléshez hozzá kell járulni az adatok kezeléséhez"
        };
        this.setState({order: order});
    };

    acceptGdpr = () => {
        const order = this.state.order;
        order["gdprConsent"] = {
            value: true,
            isValid: true,
            message: ""
        };
        this.setState({order: order});
    };

    onCaptchaValidation = (value) => {
        this.setState({captcha: value})
    };

    classString = (cond, base) => {
        return !cond ? base + " is-invalid" : base;
    };

    render() {
        return (
            <div>
                <h3>Megrendelés</h3>
                <form onSubmit={this.submitForm}>
                    <div className="form-group form-row">
                        <label htmlFor="quantity" className="col-sm-2 col-form-label">Mennyiség</label>
                        <input type="number"
                               required={true}
                               min={1}
                               max={30}
                               className={this.classString(this.state.order.quantity.isValid, "form-control col-sm-10")}
                               id="quantity"
                               placeholder="Rendelt mennyiség"
                               value={this.state.order.quantity.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small className="form-text error-block">{this.state.order.quantity.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="couponCode" className="col-sm-2 col-form-label">Kuponkód</label>
                        <input type="text"
                               className={this.classString(this.state.order.couponCode.isValid, "form-control col-sm-10")}
                               id="couponCode"
                               placeholder="Kuponkód"
                               value={this.state.order.couponCode.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small className="form-text error-block">{this.state.order.couponCode.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="paymentType" className="col-sm-2 col-form-label">Fizetési mód</label>
                        <select className="form-control col-sm-10" id="paymentType"
                                required={true}
                                value={this.state.order.paymentType.value}
                                onChange={this.inputChangeHandler}
                                disabled={this.state.inProgress}>
                            <option value="TRANSFER">Előre utalás (+ 0 Ft)</option>
                            <option value="CASH_ON_DELIVERY">Utánvét (+ 450 Ft)</option>
                        </select>
                        <small className="form-text error-block">{this.state.order.paymentType.message}</small>
                    </div>
                    <hr/>
                    <PriceSummary price={this.state.price}/>
                    <hr/>
                    <h4 className="text-left">Szállítási cím</h4>
                    <div className="form-group form-row">
                        <label htmlFor="shippingName" className="col-sm-2 col-form-label">Szállítási név</label>
                        <input type="text"
                               required={true}
                               className={this.classString(this.state.order.shippingName.isValid, "form-control col-sm-10")}
                               id="shippingName"
                               placeholder="Megrendelő neve"
                               value={this.state.order.shippingName.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small className="form-text error-block">{this.state.order.shippingName.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="shippingPhone" className="col-sm-2 col-form-label">Telefonszám</label>
                        <input type="text"
                               required={true}
                               className={this.classString(this.state.order.shippingPhone.isValid, "form-control col-sm-4")}
                               id="shippingPhone"
                               placeholder="Telefonszám"
                               value={this.state.order.shippingPhone.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label htmlFor="shippingEmail" className="col-sm-2 col-form-label">Email cím</label>
                        <input type="email"
                               required={true}
                               className={this.classString(this.state.order.shippingEmail.isValid, "form-control col-sm-4")}
                               id="shippingEmail"
                               placeholder="Megrendelő email címe"
                               value={this.state.order.shippingEmail.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                    </div>
                    <div className="form-group form-row">
                        <small
                            className="form-text error-block col-sm-6 text-left">{this.state.order.shippingPhone.message}</small>
                        <small
                            className="form-text error-block col-sm-6 text-left">{this.state.order.shippingEmail.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="shippingCity" className="col-sm-2 col-form-label">Város</label>
                        <input type="text"
                               required={true}
                               className={this.classString(this.state.order.shippingCity.isValid, "form-control col-sm-4")}
                               id="shippingCity"
                               placeholder="Város"
                               value={this.state.order.shippingCity.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label htmlFor="shippingZip" className="col-sm-2 col-form-label">Irányítószám</label>
                        <input type="number"
                               required={true}
                               className={this.classString(this.state.order.shippingZip.isValid, "form-control col-sm-4")}
                               id="shippingZip"
                               placeholder="Irsz"
                               value={this.state.order.shippingZip.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                    </div>
                    <div className="form-group form-row">
                        <small
                            className="form-text error-block col-sm-6 text-left">{this.state.order.shippingCity.message}</small>
                        <small
                            className="form-text error-block col-sm-6 text-left">{this.state.order.shippingZip.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="shippingStreet" className="col-sm-2 col-form-label">Cím</label>
                        <input type="text"
                               required={true}
                               className={this.classString(this.state.order.shippingStreet.isValid, "form-control col-sm-10")}
                               id="shippingStreet"
                               placeholder="Utca, házszám, emelet, ajtó"
                               value={this.state.order.shippingStreet.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small className="form-text error-block">{this.state.order.shippingStreet.message}</small>
                    </div>
                    <hr/>
                    <h4 className="text-left">Számlázási cím</h4>
                    <div className="form-check form-row text-left">
                        <input type="checkbox" className="form-check-input" id="hasBillingAddress"
                               checked={this.state.order.hasBillingAddress.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label className="form-check-label" htmlFor="hasBillingAddress">A számlázási cím
                            különbözik</label>
                        <small className="form-text error-block">{this.state.order.hasBillingAddress.message}</small>
                    </div>
                    <div className={this.state.order.hasBillingAddress.value ? "" : "d-none"}>
                        <div className="form-group form-row">
                            <label htmlFor="billingName" className="col-sm-2 col-form-label">Név</label>
                            <input type="text"
                                   className={this.classString(this.state.order.billingName.isValid, "form-control col-sm-10")}
                                   id="billingName"
                                   placeholder="Név"
                                   value={this.state.order.billingName.value}
                                   onChange={this.inputChangeHandler}
                                   disabled={this.state.inProgress}/>
                            <small className="form-text error-block">{this.state.order.billingName.message}</small>
                        </div>
                        <div className="form-group form-row">
                            <label htmlFor="billingCity" className="col-sm-2 col-form-label">Város</label>
                            <input type="text"
                                   className={this.classString(this.state.order.billingCity.isValid, "form-control col-sm-4")}
                                   id="billingCity"
                                   placeholder="Város"
                                   value={this.state.order.billingCity.value}
                                   onChange={this.inputChangeHandler}
                                   disabled={this.state.inProgress}/>
                            <label htmlFor="billingZip" className="col-sm-2 col-form-label">Irányítószám</label>
                            <input type="number"
                                   className={this.classString(this.state.order.billingZip.isValid, "form-control col-sm-4")}
                                   id="billingZip"
                                   placeholder="Irsz"
                                   value={this.state.order.billingZip.value}
                                   onChange={this.inputChangeHandler}
                                   disabled={this.state.inProgress}/>
                        </div>
                        <div className="form-group form-row">
                            <small
                                className="form-text error-block col-sm-6 text-left">{this.state.order.billingCity.message}</small>
                            <small
                                className="form-text error-block col-sm-6 text-left">{this.state.order.billingZip.message}</small>
                        </div>
                        <div className="form-group form-row">
                            <label htmlFor="billingStreet" className="col-sm-2 col-form-label">Cím</label>
                            <input type="text"
                                   className={this.classString(this.state.order.billingStreet.isValid, "form-control col-sm-10")}
                                   id="billingStreet"
                                   placeholder="Utca, házszám, emelet, ajtó"
                                   value={this.state.order.billingStreet.value}
                                   onChange={this.inputChangeHandler}
                                   disabled={this.state.inProgress}/>
                            <small className="form-text error-block">{this.state.order.billingStreet.message}</small>
                        </div>
                    </div>
                    <hr/>
                    <div className="form-check form-row">
                    <input type="checkbox"
                           required={true}
                           className={this.classString(this.state.order.gdprConsent.isValid, "form-check-input")}
                           id="gdprConsent"
                           checked={this.state.order.gdprConsent.value}
                           onChange={this.inputChangeHandler}
                           disabled={this.state.inProgress}/>
                    <label className="form-check-label" htmlFor="gdprConsent">Hozzájárulok az adataim
                        kezeléséhez</label>
                    <Dashboard title="Adatkezelési tájékozató"
                               onAccept={this.acceptGdpr}
                               onReject={this.rejectGdpr}>
                        <DataProtectionNotice/>
                    </Dashboard>
                    <small className="form-text error-block">{this.state.order.gdprConsent.message}</small>
                </div>
                    <div className="form-check form-row">
                        <input type="checkbox"
                               required={true}
                               className={this.classString(this.state.order.paymentConsent.isValid, "form-check-input")}
                               id="paymentConsent"
                               checked={this.state.order.paymentConsent.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label className="form-check-label" htmlFor="paymentConsent">Elfogadom, hogy a rendelés fizetési kötelezettségel jár</label>
                        <small className="form-text error-block">{this.state.order.paymentConsent.message}</small>
                    </div>
                    <div className="form-check form-row">
                        <input type="checkbox" className="form-check-input" id="mailingListConsent"
                               checked={this.state.order.mailingListConsent.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label className="form-check-label" htmlFor="mailingListConsent">Feliratkozom a hírlevélre
                            (később lemondható)&#160;</label>
                        <Tooltip hint="Oktatóanyagok, szakmai érdekességek, hetinél nem gyakrabb levelekkel">
                            <span className="fa fa-question-circle"/>
                        </Tooltip>
                        <small className="form-text error-block">{this.state.order.mailingListConsent.message}</small>
                    </div>
                    <button type="submit" className="btn btn-primary my-3"
                            disabled={this.state.inProgress}>Megrendelés
                    </button>
                    <small className="form-text error-block">{this.state.captchaMessage}</small>

                    <div className="captcha-container">
                        <ReCAPTCHA
                            sitekey="6LepJ3EUAAAAANS7dop2_jpbYKOs_cLhx1qVHMTZ"
                            onChange={this.onCaptchaValidation}/>
                    </div>
                </form>
            </div>
        )
    }
}

export default OrderForm;