import React, {Component} from "react";
import axios from "axios";
import Urls from "../util/Urls";

class NewsletterUnsubscribeForm extends Component {

    state = {
        contactEmail: {
            value: '',
            isValid: true,
            message: ''
        },
        inProgress: false
    };

    inputChangeHandler = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        const state = this.state;
        state[id] = {
            value: value,
            isValid: true,
            message: ""
        };

        this.setState({
            state
        });
    };

    submitForm = (event) => {
        event.preventDefault();
        if (!this.props.captcha) {
            this.setState({captchaMessage: "A leiratkozás előtt töltsd ki a captchát!"});
            return;
        } else {
            this.setState({captchaMessage: ""});
        }
        this.setState({
            inProgress: true
        });
        console.log("Delete to /newsletter");
        const params = {};
        params["g-recaptcha-response"] = this.props.captcha;
        params["email"] = this.state.contactEmail.value
        axios.delete(Urls.NEWSLETTER_URL, {params: params})
            .then(resp => {
                console.log("Response:");
                console.log(resp);
                this.setState({
                    inProgress: false
                });
                this.props.onSuccessfulOperation();
            })
            .catch(error => {
                if (error.response.status === 404) {
                    console.log(error.response);
                    const state = this.state;
                    state.contactEmail.isValid = false;
                    state.contactEmail.message = "Nincs aktív regisztráció a megadott email címmel";
                    state.inProgress = false;
                    this.setState({state});
                } else {
                    this.setState(() => {
                        throw error;
                    })
                }
            });
    };

    classString = (cond, base) => {
        return !cond ? base + " is-invalid" : base;
    };


    render() {
        return (
            <div className="col-sm-6">
                <h3>Leiratkozás</h3>
                <form onSubmit={this.submitForm}>
                    <div className="form-group form-row">
                        <label htmlFor="contactEmail" className="col-sm-2 col-form-label">Email</label>
                        <input type="email"
                               required={true}
                               className={this.classString(this.state.contactEmail.isValid, "form-control col-sm-10")}
                               id="contactEmail"
                               placeholder="Email cím"
                               value={this.state.contactEmail.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small
                            className="form-text error-block col-sm-12 text-left">{this.state.contactEmail.message}</small>
                    </div>
                    <button id="subscribe" type="submit" className="btn btn-primary my-3"
                            disabled={this.state.inProgress}>Leiratkozás
                    </button>
                    <small className="form-text error-block">{this.state.captchaMessage}</small>
                </form>
            </div>
        )
    }
}

export default NewsletterUnsubscribeForm;