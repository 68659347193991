import React from "react";
import axios from "axios";
import Urls from "./Urls";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
        const data = {
            error: error,
            info: info
        };
        axios.post(Urls.ERROR_URL, data)
            .then(response => {
                console.log("Error report posted to server.");
            })
            .catch(error => {
                console.log("Failed to post error report.");
            })
    }

    render() {
        if (this.state.hasError) {
            return <div className="alert alert-danger mt-2" role="alert">
                Váratlan hiba történt. Kérlek próbáld újra később.
            </div>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;