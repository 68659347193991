import React from "react";

function Tooltip(props) {
    return (
        <div className="tooltip-container">
            {props.children}
            <div className="tooltip-hint">
                {props.hint}
            </div>
        </div>
    )
}

export default Tooltip;