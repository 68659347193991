import React, {Component} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import DataProtectionNotice from "../common/DataProtectionNotice";

import Dashboard from "../util/Dashboard";
import Tooltip from "../util/Tooltip";
import Urls from "../util/Urls";

class TrainingForm extends Component {

    state = {
        contactName: {
            value: '',
            isValid: true,
            message: ''
        },
        contactEmail: {
            value: '',
            isValid: true,
            message: ''
        },
        contactPhone: {
            value: '',
            isValid: true,
            message: ''
        },
        contactMessage: {
            value: '',
            isValid: true,
            message: ''
        },
        gdprConsent: {
            value: false,
            isValid: true,
            message: ''
        },
        mailingListConsent: {
            value: false,
            isValid: true,
            message: ''
        },
        inProgress: false,
        captcha: false,
        captchaMessage: ""
    };

    inputChangeHandler = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        const state = this.state;
        state[id] = {
            value: value,
            isValid: true,
            message: ""
        };

        this.setState({
            state
        });
    };

    submitForm = (event) => {
        event.preventDefault();
        if (!this.state.captcha) {
            this.setState({captchaMessage: "A feliratkozás előtt töltsd ki a captchát!"});
            return;
        } else {
            this.setState({captchaMessage: ""});
        }
        this.setState({
            inProgress: true
        });
        const data = {};
        for (let formField in this.state) {
            if (this.state[formField].value !== undefined)
                data[formField] = this.state[formField].value;
        }
        console.log("Posting to /training");
        console.log(data);
        const params = {};
        params["g-recaptcha-response"] = this.state.captcha;
        axios.post(Urls.TRAINING_URL, data, {params: params})
            .then(resp => {
                console.log("Response:");
                console.log(resp);
                this.props.onSuccessfulOperation();
            })
            .catch(error => {
                if (error.response.status === 400) {
                    console.log(error.response);
                    this.validationHandler(error.response.data);
                    this.setState({
                        inProgress: false
                    })
                } else {
                    this.setState(() => {
                        throw error;
                    });
                }
            });
    };

    validationHandler = (errors) => {
        const state = this.state;
        for (let fieldError of errors) {
            state[fieldError.field] = {
                value: this.state[fieldError.field].value,
                isValid: false,
                message: fieldError.message
            }
        }
        this.setState({
            state
        });
    };

    rejectGdpr = () => {
        const state = this.state;
        state["gdprConsent"] = {
            value: false,
            isValid: false,
            message: "A rendeléshez hozzá kell járulni az adatok kezeléséhez"
        };
        this.setState(state);
    };

    acceptGdpr = () => {
        const state = this.state;
        state["gdprConsent"] = {
            value: true,
            isValid: true,
            message: ""
        };
        this.setState(state);
    };

    onCaptchaValidation = (value) => {
        this.setState({captcha: value})
    };

    classString = (cond, base) => {
        return !cond ? base + " is-invalid" : base;
    };

    render() {
        return (
            <div>
                <h3>Kapcsolatfelvétel</h3>
                <form onSubmit={this.submitForm}>
                    <div className="form-group form-row">
                        <label htmlFor="contactName" className="col-sm-2 col-form-label">Név</label>
                        <input type="text"
                               required={true}
                               className={this.classString(this.state.contactName.isValid, "form-control col-sm-10")}
                               id="contactName"
                               placeholder="Név"
                               value={this.state.contactName.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <small className="form-text error-block">{this.state.contactName.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="contactPhone" className="col-sm-2 col-form-label">Telefonszám</label>
                        <input type="text"
                               required={true}
                               className={this.classString(this.state.contactPhone.isValid, "form-control col-sm-4")}
                               id="contactPhone"
                               placeholder="Telefonszám"
                               value={this.state.contactPhone.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label htmlFor="contactEmail" className="col-sm-2 col-form-label">Email cím</label>
                        <input type="email"
                               required={true}
                               className={this.classString(this.state.contactEmail.isValid, "form-control col-sm-4")}
                               id="contactEmail"
                               placeholder="Email cím"
                               value={this.state.contactEmail.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                    </div>
                    <div className="form-group form-row">
                        <small
                            className="form-text error-block col-sm-6 text-left">{this.state.contactPhone.message}</small>
                        <small
                            className="form-text error-block col-sm-6 text-left">{this.state.contactEmail.message}</small>
                    </div>
                    <div className="form-group form-row">
                        <label htmlFor="contactMessage" className="col-sm-2 col-form-label">Üzenet</label>
                        <textarea
                            required={true}
                            className={this.classString(this.state.contactName.isValid, "form-control col-sm-10")}
                            id="contactMessage"
                            placeholder="Milyen témában szeretnél tanfolyamot? Milyen hosszú legyen, és hol kell megtartani? Hányan vesznek részt, és milyen előképzettséggel rendelkeznek?"
                            value={this.state.contactMessage.value}
                            onChange={this.inputChangeHandler}
                            disabled={this.state.inProgress}
                            rows={8}/>
                        <small className="form-text error-block">{this.state.contactName.message}</small>
                    </div>
                    <div className="form-check form-row">
                        <input type="checkbox"
                               required={true}
                               className={this.classString(this.state.gdprConsent.isValid, "form-check-input")}
                               id="gdprConsent"
                               checked={this.state.gdprConsent.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label className="form-check-label" htmlFor="gdprConsent">Hozzájárulok az adataim
                            kezeléséhez</label>
                        <Dashboard title="Adatkezelési tájékozató"
                                   onAccept={this.acceptGdpr}
                                   onReject={this.rejectGdpr}>
                            <DataProtectionNotice/>
                        </Dashboard>
                        <small className="form-text error-block">{this.state.gdprConsent.message}</small>
                    </div>
                    <div className="form-check form-row">
                        <input type="checkbox" className="form-check-input" id="mailingListConsent"
                               checked={this.state.mailingListConsent.value}
                               onChange={this.inputChangeHandler}
                               disabled={this.state.inProgress}/>
                        <label className="form-check-label" htmlFor="mailingListConsent">Feliratkozom a hírlevélre
                            (később lemondható)&#160;</label>
                        <Tooltip hint="Oktatóanyagok, szakmai érdekességek, hetinél nem gyakrabb levelekkel">
                            <span className="fa fa-question-circle"/>
                        </Tooltip>
                        <small className="form-text error-block">{this.state.mailingListConsent.message}</small>
                    </div>
                    <button type="submit" className="btn btn-primary my-3"
                            disabled={this.state.inProgress}>Ajánlatkérés
                    </button>
                    <small className="form-text error-block">{this.state.captchaMessage}</small>
                </form>

                <div className="captcha-container">
                    <ReCAPTCHA
                        sitekey="6LepJ3EUAAAAANS7dop2_jpbYKOs_cLhx1qVHMTZ"
                        onChange={this.onCaptchaValidation}/>
                </div>

            </div>
        )
    }
}

export default TrainingForm;