import React from "react";

import DataProtectionNotice from "../common/DataProtectionNotice";

function Gdpr(props) {

    return (
        <div className="container">
            <h3 className="text-center mb-5">Adatkezelési tájékozató</h3>

            <DataProtectionNotice/>
        </div>
    )
}

export default Gdpr;