import React from "react";

function PriceSummary(props) {
    return (
        <div>
            <div className="form-group form-row">
                <label className="col-sm-4 col-form-label">Könyvek ára</label>
                <input type="text" className="form-control col-sm-3 text-right"
                       value={props.price.orderPrice}
                       disabled={true}/>
            </div>
            <div className="form-group form-row">
                <div className="col-sm-4 text-left">
                    <label className="col-form-label">Kézbesítés típusa&#160;</label>
                </div>
                <input type="text" className="form-control col-sm-3"
                       value={props.price.shippingType === "PO_LETTER" ? "Ajánlott küldemény" : "Postacsomag"}
                       disabled={true}/>
            </div>
            <div className="form-group form-row">
                <div className="col-sm-4 text-left">
                    <label className="col-form-label">Postaköltség&#160;</label>
                </div>
                <input type="text" className="form-control col-sm-3 text-right"
                       value={props.price.shippingCost}
                       disabled={true}/>
            </div>
            <div className="form-group form-row">
                <div className="col-sm-4 text-left">
                    <label className="col-form-label">Utánvét&#160;</label>
                </div>
                <input type="text" className="form-control col-sm-3 text-right"
                       value={props.price.codCost}
                       disabled={true}/>
            </div>
            <div className="form-group form-row">
                <label className="col-sm-4 col-form-label">Osszesen</label>
                <input type="text" className="form-control col-sm-3 text-right"
                       value={props.price.orderPrice + props.price.codCost + props.price.shippingCost}
                       disabled={true}/>
            </div>
            <small className="form-text text-left">A kiállított számla a küldeménnyel érkezik, és alanyi
                adómentes.
            </small>
        </div>
    );
}

export default PriceSummary;