class Urls {
    //static BASE_URL = "https://javaoktato.com:8443/javaoktato/api";

    static BASE_URL = "https://javaoktato.com:8443/javaoktato/api";

    static NEWSLETTER_URL = "/newsletter";
    static PRICE_URL = "/orders/price";
    static ORDER_URL = "/orders";
    static TRAINING_URL = "/trainings";
    static ERROR_URL = "/errors";
    static ORDER_WRAPPING_URL = "/orders/{id}/wrapping";
    static ORDER_DELIVERY_NOTE_URL = "/orders/{id}/deliveryNote";

    static generate_wrapping_link(id) {
        return this.BASE_URL + this.ORDER_WRAPPING_URL.replace("{id}", id);
    }

    static generate_deliv_note_link(id) {
        return this.BASE_URL + this.ORDER_DELIVERY_NOTE_URL.replace("{id}", id);
    }
}

export default Urls;