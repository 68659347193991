import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import registerServiceWorker from './registerServiceWorker';
import './index.css';
import App from './App';
import Urls from "./util/Urls";

axios.defaults.baseURL = Urls.BASE_URL;

window.recaptchaOptions = {
    lang: 'hu'
};

ReactDOM.render(
    <App/>,
    document.getElementById('root'));
registerServiceWorker();
