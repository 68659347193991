import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom"

import Footer from "./common/Footer";
import Nav from "./common/Nav";

import About from "./pages/About"
import Book from "./pages/Book"
import Gdpr from "./pages/Gdpr";
import Login from "./pages/Login";
import Newsletter from "./pages/Newsletter";
import Orders from "./pages/Orders";
import Training from "./pages/Training";

import ErrorBoundary from "./util/ErrorBoundary";
import ScrollToTop from "./util/ScrollToTop";

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <ScrollToTop>
                    <div>
                        <Nav/>

                        <ErrorBoundary>
                            <Switch>
                                <Route path='/' exact component={About}/>
                                <Route path='/java_2ed' exact component={Book}/>
                                <Route path='/training' exact component={Training}/>
                                <Route path='/gdpr' exact component={Gdpr}/>
                                <Route path='/newsletter' exact component={Newsletter}/>
                                <Route path='/login' exact component={Login}/>
                                <Route path='/orders' exact component={Orders}/>
                            </Switch>
                        </ErrorBoundary>

                        <Footer/>
                    </div>
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}

export default App;